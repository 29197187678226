import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../context/StoreContext'
import { Link } from "react-router-dom"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGamepad } from '@fortawesome/free-solid-svg-icons'

export const Score = (props) => {

    // eslint-disable-next-line
    const [state, dispatch] = useContext(StoreContext)

    useEffect(() => {
        if( state.campaign === null || state.score === null ) props.history.push('/')
    }, // eslint-disable-next-line
    [])

    let table_head = state.score !== null && state.score.table !== false && state.score.table.head.map( ( field, key ) => ( <th key={key}>{field}</th> ))
    let table_datas = state.score !== null && state.score.table !== false && state.score.table.datas.map( ( data, key ) => ( <tr key={key}>{ data.map( ( field, key ) => <td key={key}>{field}</td> ) }</tr> ))

    return (
        <div className="bg-white">
        { state.score !== null && (
          <div>
              { state.score.banner !== false && ( <img className="img-fluid" alt="" src={state.score.banner} /> ) }
              <div className="p-4">
                <h1>{state.score.title}</h1>
                <p>{state.score.claim}</p>
                <p>{state.score.score}</p>
                { state.score.table !== false && (
                    <table className="table table-sm">
                        <thead><tr>{table_head}</tr></thead>
                        <tbody>{table_datas}</tbody>
                    </table>
                )}
                { !state.score.played ? ( 
                    <Link className="btn btn-lg btn-block btn-secondary" to="/play"><FontAwesomeIcon icon={faGamepad} /> Re-jouer</Link>
                ):(
                    <Link className="btn btn-lg btn-block btn-secondary" to="/">Retour</Link>
                )}
                <p className="mt-2 text-center">
                    {state.campaign.game.frequence}<br/>
                    {state.campaign.periode !== null && state.campaign.periode}
                </p>
              </div>
          </div>
        )}
        </div>
    )

}