import React, { useContext, useEffect, useRef } from 'react'
import Phaser from 'phaser'
import GamesLoader from '../../games/GamesLoader'
import CryptoJS from 'crypto-js'
import axios from 'axios'
import { Config } from '../../_helpers/Config'
import { StoreContext } from '../../context/StoreContext'
import MobileDetect from 'mobile-detect'

// IMPORTANT : Change imageInlineSizeLimit to 0 in /node_modules/react-scripts/config/webpack.config.js

const config = {
  type: Phaser.AUTO,
  parent: "phaser-game",
  input : { activePointers: 3 },
  width: 500,
  height: 700,
  physics: {
    default: 'arcade',
    arcade: {
      debug: false
    }
  }
}

export const Games = (props) => {

  const [state, dispatch] = useContext(StoreContext)
  const game_wrapper = useRef(null)

  const gameOver = (o) => {
      o.campaign = parseInt(state.campaign.id)
      let h = CryptoJS.AES.encrypt(JSON.stringify(o), Config.hash, {format: Config.CryptoJSAesJson}).toString()
      axios.post(`${Config.serveur}/gameover`, { h })
      .then( response => {
          if( response.status === 200 ) {
            dispatch({ type: "SET_SCORE", payload: response.data })
            setTimeout(() => props.history.push('/score'), 2000)
          }
      }).catch( error => {
        alert('Désolé, une erreur est survenue, pour des raisons de sécurité vous avez été déconnecté.')
        document.location = '/signout'
    })
  }

  useEffect(() => {

    if( state.campaign !== null ) {

      if( !state.played ) {
        const game = new Phaser.Game(config)
        GamesLoader(game)
        config.gameOver = gameOver
        game.scene.start(state.campaign.game.source, config)
      } else {
        props.history.push('/')
      }
      
    } else props.history.push('/')

    window.addEventListener('resize', resizeGame, false)
    resizeGame()

  }, // eslint-disable-next-line
  [])

  const resizeGame = () => {
    if( game_wrapper.current !== null ) {
      const canvas = game_wrapper.current.children[0]
      if( canvas !== undefined ) {
        canvas.focus()
        const windowWidth = window.innerWidth - 50
        const gameRatio = config.width / config.height
        if ( windowWidth < config.width ) {
          canvas.style.width = `${windowWidth}px`
          canvas.style.height = `${windowWidth / gameRatio}px`
        } else {
          canvas.style.width = `${config.width}px`
          canvas.style.height = `${config.height}px`
        }
      }
    }
  }

  const is_mobile = new MobileDetect()
  const legende = state.campaign !== null ? ( is_mobile.mobile() === null ? state.campaign.game.legende : ( state.campaign.game.legende_mobile !== null ? state.campaign.game.legende_mobile : state.campaign.game.legende ) ) : null

  return (
    <>
      <div id="games" className="d-flex bg-white justify-content-center">
          <div ref={game_wrapper} id="phaser-game"></div>
      </div>
      <div className="bg-white text-center p-4"><b>Comment jouer ?</b> {legende}</div>
    </>
  )

}