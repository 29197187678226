import React from 'react'
import { Route, Redirect } from "react-router-dom"
import { AuthenticationService } from '../components/auth/AuthenticationService'

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        AuthenticationService.currentUser !== null
        ? <Component {...props} />
        : <Redirect to='/signin' />
    )} />
  )