import React from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom"

import { StoreContextProvider } from './context/StoreContext'

import { history } from './_helpers/History';
import { PrivateRoute } from './_helpers/PrivateRoute'

import Signin from './components/auth/Signin'
import Signout from './components/auth/Signout'
import { Home } from './components/Home'
import Header from './components/Header'

import { Games } from './components/gaming/Games'
import { Score } from './components/gaming/Score'

function App() {

  document.body.className = "bg-primary min-vh-100"

  return (
    <StoreContextProvider>
      <div style={{maxWidth:"800px"}} className="container">
        <Router history={history}>
          <Header />
          <Route exact path="/" component={Home} /> 
          <Route path="/signin" component={Signin} /> 
          <Route path="/signout" component={Signout} /> 
          <PrivateRoute path="/play" component={Games} /> 
          <PrivateRoute path="/score" component={Score} /> 
        </Router>
      </div>
    </StoreContextProvider>
  )
}

export default App