import React, { useReducer, createContext } from 'react'

export const StoreContext = createContext();

const initialState = {
    campaign: null,
    played: false,
    score: null,
    loading: true,
};

const reducer = (state, action) => {

    switch (action.type) {
        /*case "ADD_CONTACT":
            return {
                contacts: [...state.contacts, action.payload]
            };
        case "DEL_CONTACT":
            return {
                contacts: state.contacts.filter(
                    contact => contact.id !== action.payload
                )
            };*/
        case "LOAD":
            return {
                ...state,
                campaign: action.payload.campaign,
                played: action.payload.campaign != null && action.payload.campaign.game.played,
                loading: false
            };
        case "SET_SCORE":
            return {
                ...state, 
                score: action.payload,
                played: action.payload.played
            };
        default:
            throw new Error();
    }

};

export const StoreContextProvider = props => {

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <StoreContext.Provider value={[state, dispatch]}>
            {props.children}
        </StoreContext.Provider>
    );

}