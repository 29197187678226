import Phaser, { Scene } from 'phaser'

import VirtualJoystick from 'phaser3-rex-plugins/plugins/virtualjoystick'
import background from './assets/background.jpg'
import ship1 from './assets/ship1.png'
import ship2 from './assets/ship2.png'
import ship3 from './assets/ship3.png'
import player from './assets/player.png'
import explosion from './assets/explosion.png'
import big_explosion from './assets/big_explosion.png'
import bullet from './assets/bullet.png'
import header from './assets/header.jpg'
import gameover from './assets/gameover.png'

export default class SpaceCraft extends Scene {

    config = null

    gameConfig = {
        ennemys_ship : ["ship1","ship2","ship3"],
        lifes: 4
    }

    constructor() {
        super('SpaceCraft')
    }

    init(config) {
        this.config = config
    }

    preload() {
        this.load.image("background", background)
        this.load.image("header", header)
        this.load.image("gameover", gameover)

        this.load.spritesheet("ship1", ship1, { frameWidth: 16, frameHeight: 16 })
        this.load.spritesheet("ship2", ship2, { frameWidth: 32, frameHeight: 16 })
        this.load.spritesheet("ship3", ship3, { frameWidth: 32, frameHeight: 32 })
        this.load.spritesheet("player", player, { frameWidth: 64, frameHeight: 64 })
        this.load.spritesheet("explosion", explosion, { frameWidth: 30, frameHeight: 30 })
        this.load.spritesheet("big_explosion", big_explosion, { frameWidth: 50, frameHeight: 50 })
        this.load.spritesheet("bullet", bullet, { frameWidth: 16, frameHeight: 16 })

        this.current_lifes = this.gameConfig.lifes
        this.current_score = 0
        this.is_playing = true
    }

    create() {

        // GLOBALS
        this.spacebar = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);
        this.cursorKeys = this.input.keyboard.createCursorKeys()

        // JOYSTICK
        this.joystick = new VirtualJoystick(this, {
            x: 70,
            y: this.config.height - 70,
            base: this.add.circle(0, 0, 50, 0xE73935, 80),
            thumb: this.add.circle(0, 0, 25, 0xE73935, 10),
            radius: 50
        })

        // SCORE ETC...
        this.add.sprite(this.config.width / 2, 44, "header").setDepth(99)
        this.gameover = this.add.sprite( this.config.width / 2, -200, "gameover")
        this.gameover.setVisible(false)

        this.score = this.add.text(98, 16, this.current_score, { fontFamily:'Arial', fontSize: '25px', color: '#e53c3b' }).setDepth(99)
        this.lifes = this.add.text(444, 16, this.current_lifes, { fontFamily:'Arial', fontSize: '25px', color: '#e53c3b' }).setDepth(99)

        // BACKGROUND
        this.background = this.add.tileSprite(0, 0, this.config.width, this.config.height, "background")
        this.background.setOrigin(0, 0)
        this.background.setDepth(-1)

        // ANIMATIONS
        this.anims.create({ key: "ship1_anim", frames: this.anims.generateFrameNumbers("ship1"), frameRate: 20, repeat: -1 })
        this.anims.create({ key: "ship2_anim", frames: this.anims.generateFrameNumbers("ship2"), frameRate: 20, repeat: -1 })
        this.anims.create({ key: "ship3_anim", frames: this.anims.generateFrameNumbers("ship3"), frameRate: 20, repeat: -1 })
        this.anims.create({ key: "player_anim", frames: this.anims.generateFrameNumbers("player"), frameRate: 20, repeat: -1 })
        this.anims.create({ key: "explosion_anim", frames: this.anims.generateFrameNumbers("explosion"), frameRate: 20, repeat: 0, hideOnComplete: true })
        this.anims.create({ key: "big_explosion_anim", frames: this.anims.generateFrameNumbers("big_explosion"), frameRate: 20, repeat: 0, hideOnComplete: true })
        this.anims.create({ key: "bullet_anim", frames: this.anims.generateFrameNumbers("bullet"), frameRate: 20, repeat: -1 })

        // PLAYER
        this.player = new Player(this)

        // LASER AND ENNEMYS
        this.laserGroup = new LaserGroup(this)
        this.ennemysGroup = new EnnemyShipGroup(this)

        // COLLISIONS
        this.physics.add.overlap(this.laserGroup, this.ennemysGroup, this.killEnnemy.bind(this))
        this.physics.add.overlap(this.ennemysGroup, this.player, this.killPlayer.bind(this))

        // CLICK TO SHOOT
        this.input.on("pointerdown", () => { 
            if( this.is_playing && this.player.active ) {
                this.laserGroup.fireLaser(this.player.x, this.player.y) 
            }
        }, this);

    }

    update() {
        this.background.tilePositionY -= 1
        this.player.move()
        this.ennemysGroup.addEnnemy()

        // SPACEBAR TO SHOOT
        if (Phaser.Input.Keyboard.JustDown(this.spacebar)) {
            if( this.is_playing && this.player.active ) {
                this.laserGroup.fireLaser(this.player.x, this.player.y - 32)
            }
        }

        if(this.is_playing) this.gamePlay()
    }

    killEnnemy(laser, ennemy) {
        if ( laser.active && ennemy.active && this.is_playing ) {
            this.current_score += 1
            this.score.setText(this.current_score)
            ennemy.kill()
            laser.remove()
        }
    }

    killPlayer(player, ennemy) {
        if ( ennemy.active && player.active && player.alpha === 1 && this.is_playing ) {
            this.current_lifes -= 1
            this.lifes.setText(this.current_lifes)
            ennemy.kill()
            player.kill()
        }
    }

    gameOver() {

        this.gameover.setVisible(true)
        this.tweens.add({
            targets: this.gameover,
            y: { from: -200, to: this.config.height / 2 },
            ease:'Bounce',
            duration: 2000,
            repeat:0
        })
        
        this.config.gameOver({score:this.current_score})
    }

    // GAMEPLAY OF GAME
    gamePlay() {
        if( this.current_lifes <= 0 ) {
            this.is_playing = false
            this.time.addEvent({ delay: 3000, callback: this.destroyGame, callbackScope: this, loop: false })
            this.gameOver()
        }
    }

    destroyGame() {
        this.sys.game.destroy(true)
    }

}

class Player extends Phaser.Physics.Arcade.Sprite {

    constructor(scene, x, y) {
        super(scene, x, y, 'player')
        scene.add.existing(this)
        scene.physics.add.existing(this)
        this.play("player_anim")
        this.init()
    }

    init() {
        this.setActive(true)
        this.setVisible(true)
        this.setAlpha(0.5)
        this.setPosition(this.scene.config.width / 2 - 8, this.scene.config.height + 64)

        this.scene.tweens.add({
            targets: this,
            y: this.scene.config.height - 64,
            ease: 'Power1',
            duration: 1500,
            repeat: 0,
            onComplete: function() {
                this.setAlpha(1)
                this.setCollideWorldBounds(true)
            },
            callbackScope: this
        })
    }

    move() {
        this.setVelocity(0);

        let speed = 200
        if(this.scene.joystick.left || this.scene.joystick.right || this.scene.joystick.up || this.scene.joystick.down) speed = this.scene.joystick.force * 2

        if (this.scene.cursorKeys.left.isDown || this.scene.joystick.left) {
            this.setVelocityX(-speed)
        } else if (this.scene.cursorKeys.right.isDown || this.scene.joystick.right) {
            this.setVelocityX(speed)
        }

        if (this.scene.cursorKeys.up.isDown || this.scene.joystick.up) {
            this.setVelocityY(-speed)
        } else if (this.scene.cursorKeys.down.isDown || this.scene.joystick.down) {
            this.setVelocityY(speed)
        }
    }

    kill() {
        this.setVelocity(0,0)
        new BigExplosion(this.scene, this.x, this.y)
        this.setActive(false)
        this.setVisible(false)
        this.setCollideWorldBounds(false)

        if( this.scene.is_playing ) {
            // WAITING BEFORE RESET POSITION
            this.scene.time.addEvent({
                delay: 1000,
                callback: this.init,
                callbackScope: this,
                loop: false
            })
        }
    }

}

class Explosion extends Phaser.Physics.Arcade.Sprite {

    constructor(scene, x, y) {
        super(scene, x, y, 'explosion')
        scene.add.existing(this)
        this.play("explosion_anim")
    }

}

class BigExplosion extends Phaser.Physics.Arcade.Sprite {

    constructor(scene, x, y) {
        super(scene, x, y, 'big_explosion')
        scene.add.existing(this)
        this.play("big_explosion_anim")
    }

}


class EnnemyShipGroup extends Phaser.Physics.Arcade.Group {

    constructor(scene) {
        super(scene.physics.world, scene)
        this.createMultiple({
            classType: EnnemyShip,
            frameQuantity: 10,
            active: false,
            visible: false,
            key: 'ennemys_ship'
        })
    }

    addEnnemy() {
        if( this.scene.current_lifes > 0 ) {
            const ennemy = this.getFirstDead(false)
            if (ennemy) ennemy.new()
        }
    }

}

class EnnemyShip extends Phaser.Physics.Arcade.Sprite {

    ship = null

    constructor(scene, x, y) {
        super(scene, x, y, 'ship1');
    }

    new() {
        this.ship = Phaser.Math.RND.pick(this.scene.gameConfig.ennemys_ship)
        this.setTexture(this.ship)
        this.body.reset(Phaser.Math.Between(0, this.scene.config.width), 0)
        this.setActive(true)
        this.setVisible(true)
        this.setVelocityY(Phaser.Math.Between(150, 250))
        this.play(this.ship + "_anim")
    }

    preUpdate(time, delta) {
        super.preUpdate(time, delta)
        if (this.y >= this.scene.config.height) {
            this.setActive(false)
            this.setVisible(false)
        }
    }

    kill() {
        this.setVelocityY(0)
        new Explosion(this.scene, this.x, this.y)
        this.setActive(false)
        this.setVisible(false)
    }
}


class LaserGroup extends Phaser.Physics.Arcade.Group {

    constructor(scene) {
        super(scene.physics.world, scene)
        this.createMultiple({
            classType: Laser,
            frameQuantity: 30,
            active: false,
            visible: false,
            key: 'bullet'
        })
    }

    fireLaser(x, y) {
        const laser = this.getFirstDead(false)
        if (laser) laser.fire(x, y)
    }

}

class Laser extends Phaser.Physics.Arcade.Sprite {

    constructor(scene, x, y) {
        super(scene, x, y, 'bullet');
    }

    fire(x, y) {
        this.body.reset(x, y)
        this.setActive(true)
        this.setVisible(true)
        this.setVelocityY(-400)
    }

    preUpdate(time, delta) {
        super.preUpdate(time, delta)
        if (this.y <= 0) {
            this.setActive(false)
            this.setVisible(false)
        }
    }

    remove() {
        this.setActive(false)
        this.setVisible(false)
    }

}