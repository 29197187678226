import React from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { AuthenticationService } from './AuthenticationService'

import logo from "../../assets/logo.png"

export default class Signin extends React.Component {

    constructor(props) {
        super(props)
        if ( AuthenticationService.currentUser !== null ) { 
            this.props.history.push('/');
        }
    }

    render() {

        document.body.className = "bg-primary d-flex align-items-center vh-100 justify-content-center"

        return (
            <div style={{width:'400px'}} className="container bg-white rounded shadow p-4">

                <img className="mb-4" src={logo} alt="Logo ZEOP" />
                
                <Formik

                    initialValues={{
                        username: '',
                        password: ''
                    }}

                    validationSchema={Yup.object().shape({
                        username: Yup.string().required('Identifant invalide'),
                        password: Yup.string().required('Mot de passe invalide')
                    })}

                    onSubmit={({ username, password }, { setStatus, setSubmitting }) => {
                        setStatus();
                        AuthenticationService.login(username, password)
                        .then(
                            user => {
                                console.log("user", user)
                                document.location.href = "/"
                            },
                            error => {
                                console.log("error", error)
                                setSubmitting(false)
                                setStatus(error)
                            }
                        )
                    }}

                    render={({ errors, status, touched, isSubmitting }) => (
                        <Form>
                            {status && <div className={'alert alert-danger'}>{status}</div>}
                            <div className="form-group">
                                <label htmlFor="username">Votre identiant Zeop</label>
                                <Field name="username" autoComplete="off" type="text" className={'form-control' + (errors.username && touched.username ? ' is-invalid' : '')} />
                                <ErrorMessage name="username" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Mot de passe</label>
                                <Field name="password" autoComplete="off" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                <ErrorMessage name="password" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-secondary mr-2" disabled={isSubmitting}>{!isSubmitting?"Se connecter":"Veuillez patienter..."}</button> 
                                {isSubmitting && <img alt="Veuillez patienter..." src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />}
                            </div>
                        </Form>
                    )}
                />
            </div>
        )

    }

}