import Phaser, { Scene } from 'phaser'

import card from './assets/card.png'
import emo from './assets/emo_sprite.png'
import gameover from './assets/gameover.png'

export default class MemoryCards extends Scene {

    config = null

    gameConfig = {
        level : 1,
        chrono: 120
    }

    constructor() {
        super('MemoryCards')
    }

    init(config) {
        this.config = config
    }

    preload() {
        this.cameras.main.backgroundColor = Phaser.Display.Color.HexStringToColor("#ffbc01")
        this.load.image("gameover", gameover)
        this.load.spritesheet("card", card, { frameWidth: 200, frameHeight: 200 })
        this.load.spritesheet("emo", emo, { frameWidth: 56, frameHeight: 56 }, 189)
    }

    create() {
        this.current_score = 0
        this.is_playing = true
        
        this.gameover = this.add.sprite( this.config.width / 2, -200, "gameover")
        this.gameover.setDepth(999)
        this.gameover.setVisible(false)

        this.levelText = this.add.text(10, 10, "", { font: "40px Arial Black", fill: "#67175F", fontWeight: 'bold' })
        this.scoreText = this.add.text(10, 50, "Score " + this.current_score, { font: "40px Arial Black", fill: "#67175F", fontWeight: 'bold' })
        this.chronoText = this.add.text(400, 10, this.formatTime(this.gameConfig.chrono), { font: "40px Arial Black", fill: "#E53C3B", fontWeight: 'bold' })

        this.start()

        // COUNTDOWN
        this.timer = this.time.addEvent({ delay: 1000, callback: this.countDown, callbackScope: this, loop: true })
    }

    // START A GAME
    start() {

        this.levelText.setText("LEVEL " + this.gameConfig.level)

        // INIT SCORE AND PLAYING
        this.levelCardsWin = 0
        const level = this.gameConfig.level
        const margin = 20
        const nbr = Math.pow( level * 2 , 2)
        this.levelCardsToWin = nbr / 2
        const emos = this.getEmos(nbr)
        const bloc_per_line = Math.sqrt(nbr)
        const size = Math.round((this.config.width / bloc_per_line) - (margin / 2))
        this.cards = this.add.group()

        for (let i = 1; i <= nbr; i++) {

            // GET SPRITE CARD
            let card = this.add.sprite(0, 0, 'card')
            card.displayWidth = size
            card.scaleY = card.scaleX

            // GET EMO CARD
            let emokey = emos.pop()
            let emo = this.add.sprite(0, 0, "emo", emokey)
            emo.displayWidth = size / 2
            emo.scaleY = emo.scaleX
            emo.setVisible(false)

            // ASSOCIATE IN A CONTAINER
            let container = this.add.container(0, 0)
            container.add([card, emo])
            container.setSize(size, size)
            container.setActive(true)
            container.setData("card", emokey)
            container.setInteractive({useHandCursor: true}).on('pointerdown', this.clickCard.bind(this, container))

            // ADD TO GROUP OF CARDS
            this.cards.add(container)

        }

        Phaser.Actions.GridAlign(this.cards.getChildren(), {
            width: bloc_per_line,
            height: bloc_per_line,
            cellWidth: size + margin / 2,
            cellHeight: size + margin / 2,
            x: size / 2 + (margin / 2),
            y: size / 2 + (margin / 2) + 150
        })

        // INIT VARS
        this.canReturnCard = true
        this.returnCards = []

    }

    formatTime(seconds){
        var minutes = Math.floor(seconds/60)
        var partInSeconds = seconds%60
        partInSeconds = partInSeconds.toString().padStart(2,'0')
        return `${minutes}:${partInSeconds}`;
    }

    countDown() {
        this.gameConfig.chrono -= 1;
        if( this.gameConfig.chrono >= 0 ) this.chronoText.setText(this.formatTime(this.gameConfig.chrono))
        else this.timer.remove()
    }

    // HANDLE CLICK CARD
    clickCard(gameObject, pointer, x, y, PropagationObj) {

        if( this.canReturnCard && gameObject.active && this.is_playing ) {
            
            if( this.returnCards.length < 2 ) {
                this.returnCards.push(gameObject)

                // SHOW BACK OF CARD
                gameObject.setActive(false)
                gameObject.first.setFrame(1)
                gameObject.last.setVisible(true)
            }

            // SI DEUX CARTES RETOURNEES
            if( this.returnCards.length === 2 ) {

                this.canReturnCard = false

                if( this.returnCards[0].getData("card") === this.returnCards[1].getData("card") ) { // WIN

                    this.current_score += 1

                    // SHOW WIN OF CARDS
                    for( let g = 0; g < this.returnCards.length; g++ ) {
                        this.returnCards[g].first.setFrame(2)
                        this.returnCards[g].setActive(false)
                    }

                    this.time.addEvent({
                        delay: 500,
                        callback: () => {
                            // WINNER CARDS COMBINAISON
                            this.levelCardsWin += 1
                            // RESET VARS
                            this.returnCards = []
                            this.canReturnCard = true
                        },
                        callbackScope: this,
                        loop: false
                    })

                } else { // LOOSE
                    
                    // SHOW LOOSE
                    for( let g = 0; g < this.returnCards.length; g++ ) this.returnCards[g].first.setFrame(3)

                    this.time.addEvent({
                        delay: 500,
                        callback: () => {

                            // SHOW FRONT OF CARDS
                            for( let g = 0; g < this.returnCards.length; g++ ) {
                                this.returnCards[g].first.setFrame(0)
                                this.returnCards[g].last.setVisible(false)
                                this.returnCards[g].setActive(true)
                            }

                            // RESET VARS
                            this.returnCards = []
                            this.canReturnCard = true
                        },
                        callbackScope: this,
                        loop: false
                    })
                }
            }
        }
    }

    // NEXT LEVEL IF WIN
    nextLevel() {
        if( this.is_playing ) {
            this.cards.clear(true, true)
            this.gameConfig.level += 1
            this.start()
        }
    }

    update() {
        this.scoreText.setText("Score " + this.current_score)
        if( this.levelCardsWin === this.levelCardsToWin ) this.nextLevel()
        if( this.is_playing ) this.gamePlay()
    }

    getEmos(nbr) {
        let n = nbr / 2
        let emos = []
        while ( emos.length < n ) {
            let emoKey = Phaser.Math.Between(0, 188)
            if( !emos.includes(emoKey) ) emos.push(emoKey)
        }
        emos = emos.concat(emos)
        emos.sort(() => Math.random() - 0.5)
        return emos
    }

    gameOver() {

        this.gameover.setVisible(true)
        this.tweens.add({
            targets: this.gameover,
            y: { from: -200, to: this.config.height / 2 },
            ease:'Bounce',
            duration: 2000,
            repeat:0
        })
        
        this.config.gameOver({score:this.current_score})

    }

    // GAMEPLAY OF GAME
    gamePlay() {
        if( this.gameConfig.chrono === 0 ) {
            this.is_playing = false
            this.time.addEvent({ delay: 3000, callback: this.destroyGame, callbackScope: this, loop: false })
            this.gameOver()
        }
    }

    destroyGame() {
        this.sys.game.destroy(true)
    }

}