import React, { useContext, useEffect } from 'react'
import { Link } from "react-router-dom"

import { Config } from '../_helpers/Config'
import { AuthenticationService } from './auth/AuthenticationService'
import { StoreContext } from '../context/StoreContext'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGamepad } from '@fortawesome/free-solid-svg-icons'

export const Home = (props) => {

    const [state, dispatch] = useContext(StoreContext)

    useEffect(() => {
        if ( AuthenticationService.currentUser === null ) return props.history.push('/signin')
        else if( state.campaign === null ) return fetchCampaign()
    }, // eslint-disable-next-line
    [])

    const fetchCampaign = () => {
        axios
        .get(`${Config.serveur}/campaign`)
        .then(response => {
            if( response.status === 200 ) {
                dispatch({ type: "LOAD", payload: response.data })
            } 
        }).catch( error => {
            alert('Désolé, impossible de charger votre profil, pour des raisons de sécurité vous allez être déconnecté.')
            document.location = '/signout'
        })
    }

    return (
        <div className="bg-white">
            { state.loading ? (
                <div className="alert alert-info">Chargement en cours...</div>
            ) : (
                <>
                    { state.campaign !== null ? (
                        <div>
                            { state.campaign.banner !== false && ( <img className="img-fluid" alt="" src={state.campaign.banner} /> ) }
                            <div className="p-4">
                                <h3>{state.campaign.title}</h3>
                                <p className="mb-4">{state.campaign.claim}</p>
                                { !state.played ? ( 
                                    <Link className="btn btn-lg btn-block btn-secondary" to="/play"><FontAwesomeIcon icon={faGamepad} /> Lancer le jeu</Link>
                                ):(
                                    <div className="alert alert-warning">Désolé vous avez déjà tenté votre chance !</div>
                                )}
                                <p className="mt-2 text-center">
                                    {state.campaign.game.frequence}<br/>
                                    {state.campaign.periode !== null && state.campaign.periode}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className="alert alert-danger">Désolé, aucune campagne de jeu en cours.</div>
                    )}

                </>
            ) }
        </div>
    )

}